<template>
  <div>
    <!-- Fortnox section start -->
    <section class="home-section-04">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <img
              src="@/assets/duplioAsset/duplioAssets/fortnox-integrationspartner2.png"
              class="main"
          />
        </div>

        <div class="col-lg-6">
          <div class="text" style="margin: 0 15px">
            <h4>
              {{ $t('FooterLayoutFirst.header') }}
            </h4>
            <p>
              {{ $t('FooterLayoutFirst.subtitle') }}
            </p>
            <div class="item">
              <div class="icon">
                <img
                    src="@/assets/duplioAsset/duplioAssets/icons/i01.svg"
                />
              </div>

              <p>
                {{ $t('FooterLayoutFirst.list.firstList') }}
              </p>
            </div>

            <div class="item">
              <div class="icon">
                <img
                    src="@/assets/duplioAsset/duplioAssets/icons/i02.svg"
                />
              </div>

              <p>{{ $t('FooterLayoutFirst.list.secondList') }}</p>
            </div>

            <div class="item">
              <div class="icon">
                <img
                    src="@/assets/duplioAsset/duplioAssets/icons/i01.svg"
                />
              </div>

              <p>{{ $t('FooterLayoutFirst.list.thirdList') }}</p>
            </div>

            <a href="https://www.fortnox.se/paket/" class="button">
              {{ $t('FooterLayoutFirst.list.link') }}
              <img
                  src="@/assets/duplioAsset/duplioAssets/icons/arrow-right-blue.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- Fortnox section End -->
    <!-- Footer -->
    <footer class="mainfooter">
      <div class="container">
        <div class="row mx-2">
          <div class="col-lg-6">
            <router-link class="logo" :to="{name: 'home'}">
              <img
                  src="@/assets/duplioAsset/duplioAssets/DUPLIO6.png"
              />
            </router-link>
            <p class="description">
              {{ $t('FooterLayoutFirst.description') }}
            </p>
          </div>

          <div class="col-lg-3">
            <ul class="menu">
              <h4 class="text-white">
                {{ $t('FooterLayoutFirst.headerLink') }}
              </h4>
              <li v-for="(link, index) in dataLink" :key="index">
                <a
                    v-if="link.key !== 'terms_of_purchase'"
                    :href="link.route"
                >
                  {{ link.label }}
                </a>
                <a download="DUPLIO TERMS OF PURCHASE"
                   v-else
                   href="../../DUPLIO_TERMS_OF_PURCHASE.pdf"
                >
                  {{ link.label }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
                        <span class="copyright">
                            Connectivo AB
                        </span>
          </div>
          <div class="col-lg-12">
                        <span class="copyright">
                            © {{ new Date().getFullYear() }} Duplio
                            {{ $t('AppFooter.reserved') }}
                        </span>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLink: this.$t('FooterLayoutFirst.link'),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/footer.scss';
</style>
